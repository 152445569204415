
import $ from 'jquery';
import slick from 'vendor/jquery/slick-carousel';

const $window = $(window);

export const initInstance = function(el) {

	const $slider = $(el);

	$slider.slick({
		variableWidth: true,
		centerMode: false,
		dots: true,
		infinite: false,
		responsive : [
			{
				breakpoint : 800,
				settings : {
					variableWidth: false,
				}
			}
		]
	});

	setTimeout(function() {
		// odd layout bug
		$window.trigger('resize');
	}, 100);
}
